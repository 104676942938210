/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "bootstrap/dist/css/bootstrap.min.css"
import "katex/dist/katex.min.css"
import "@fortawesome/fontawesome-svg-core/styles.css"
import { config } from "@fortawesome/fontawesome-svg-core"
// You should do that in a Layout file or in `gatsby-browser.js`.
config.autoAddCss = false

// import "font-awesome/css/font-awesome.min.css"
